<style scoped>
/* import scoped css file */
@import '@/assets/css/converse.css';
</style>

<template>
  <v-container class="full-page-container text-center">
    <v-row class="headliner d-flex justify-center">
      <v-col cols="12" md="2">
        <div>
          <v-img max-height="60" src="@/assets/triz_participacoes.webp"></v-img>
        </div>
      </v-col>
      <v-col cols="12" md="10">
        <div class="headline-wrapper">
          <div id="cursor" style="width: 10px; height: 20px; background-color: transparent;"></div>
          <!--  <div id="cursor" style="display: inline-block; width: 10px; height: 20px; background-color: transparent;"></div> -->
        </div>
      </v-col>
    </v-row>
    <!--  <v-row class="first-row content-wrapper" style="align-items: center;" justify-center> -->
    <v-row class="first-row content-wrapper bg-blue-lighten-3" style="align-items: center;" justify-center>
      <v-row class="first-row  protocol-wrapper" style="height: 92%;">
        <div class="px-6" style="width: 96%; margin: 0 auto; padding: 16px; overflow-y: auto" id="protocolview">
        
       <HoverCard v-for="(question, index) in questions" :key="index" :question="question" @question-clicked="handleQuestionClick" v-if="questions.length > 0" />
        
        </div>
      </v-row>
    </v-row>
    <v-row class="spacer"></v-row>
    <v-row class="second-row content-wrapper bg-blue-lighten-3" justify-center>
      <v-col cols="12" md="10">
        <div>
          <v-form @submit.prevent="submitForm">
            <v-textarea id="form_inputline" class="bg-blue-lighten-4" v-model="message" :label="inputLine" required no-resize rows="3" hide-details
              @keydown.ctrl.enter="submitForm"></v-textarea>
          </v-form>
        </div>
      </v-col>
      <v-col cols="12" md="2">
        <div align-center>
          <v-btn id="form_submitbutton" block color="light-blue-darken-1" @click="submitForm">enviar</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import HoverCard from './HoverCard.vue';
import { VueElement } from 'vue';

export default {
  components: {
    HoverCard
  },
  data() {
    return {
      inputLine: '',
      showHoverCard: false,
      message: '',
      messages: [], // array to store user submitted messages
      sessionId: '',
      configData: {}, // to store configuration data from API
      questions: [] // to store Welcome questions from API
    }
  },
  mounted() {
    // Call the method to fetch configuration data and questions from API when component is mounted
    // Pass the language code retrieved from the browser
    (async () => {
      // call fetch configuration
      // Pass language code from browser
      const browserLanguage = this.getBrowserLanguage();
      await this.fetchConfigData(browserLanguage);
      this.inputLine = this.configData.ui_inputline_prompt;
      this.showHoverCard = true;
      console.log("inputLine: ", this.inputLine);
    })();
  },
  methods: {
    // Method to fetch configuration data and questions from API
      async fetchConfigData(language) {
        document.body.style.cursor = 'progress'
        const hostname = window.location.hostname;
        let apiUrl;
        if (hostname === 'saude.trizpar.com.br') {
          apiUrl = `https://saude.trizpar.com.br:5002/api/getwelcome?lang=${language}`; // production environment
        } else if (hostname === 'test.saude.trizpar.com.br') {
          apiUrl = `https://test.saude.trizpar.com.br:5003/api/getwelcome?lang=${language}`; // staging environment
        } else {
          apiUrl = `http://192.168.1.110:5000/api/getwelcome?lang=${language}`; // dev environment
        }

      // const apiUrl = `http://192.168.1.110:5000/api/getwelcome?lang=${language}`;
      return fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          // Update component data with received configuration and questions
          this.configData = {...data};
          this.questions = data.questions;
          this.inputLine = this.configData.ui_inputline_prompt;
          console.log("methods: this.inputLine: ", this.inputLine)
          console.log(this.configData);
          console.log(this.questions);
          // Call method to append welcome message 
          this.updateTranslatedUI(data.ui_submitbutton_text)
          if (data.menu == true) {
            this.appendWelcomeMessage(data.msg, data.subtitle, data.msg_on, data.subtitle_on);
          }
          document.body.style.cursor = 'auto'
          return this.configData;
        })
        .catch(error => {
          console.error('Error fetching configuration data:', error);
        });
    },
    updateTranslatedUI(ui_submitbutton_text) {
      const submitButton = document.getElementById('form_submitbutton');
      submitButton.textContent = ui_submitbutton_text;
    },
    // Method to append welcome message to protocolView
    appendWelcomeMessage(msg, subtitle, msg_on, subtitle_on) {
      const protocolView = document.getElementById('protocolview');
      if (msg_on == true) {
        const welcomeHeader = document.createElement('p');
        welcomeHeader.className = "text-h2 font-weight-black text-md-center text-white py-2";
        welcomeHeader.textContent = msg;
        protocolView.appendChild(welcomeHeader);
      }
      if (subtitle_on == true) {
        const welcomeSubtitle = document.createElement('p');
        welcomeSubtitle.className = "text-h5 text-md-center font-weight-bold text-grey-lighten-2 bg-blue pa-2 mb-8";        
        welcomeSubtitle.textContent = subtitle;
        protocolView.appendChild(welcomeSubtitle);
      }
    },
    handleQuestionClick(question) {
      console.log("Clicked on question: ", question);
      this.message = (question);
      this.submitForm();
    },
    // Method to retrieve language code from browser
    getBrowserLanguage() {
      return navigator.language || navigator.userLanguage;
    },
    submitForm() {
      // Get the message content and split it by line breaks
      const messageContent = this.message.trim().split('\n')

      console.log("sessionId:", this.sessionId)

      // clear v-textarea
      this.message = '';

      // add the message to the messages array
      this.messages.push(...messageContent);


      // create the json object containing message and session_id
      const data = {
        message: messageContent.join('\n'),
        session_id: this.sessionId || '' // send empty string if this.sessionId not set
      };

      console.log("json data: ", data)

      // start blinking cursor
      const blinkInterval = setInterval(toggleCursor, 250); // adjust blink interval here

      // append each line to the chat protocol
      const protocolView = document.getElementById('protocolview');
      messageContent.forEach((line, index) => {
        const paragraph = document.createElement('p');
        paragraph.className = "px-6 mt-6 text-h6 font-weight-medium font-italic bg-blue text-white"
        paragraph.textContent = line;
        protocolView.appendChild(paragraph);

        // Add an empty line after each post, except for the last one
        if (index == messageContent.length - 1) {
          protocolView.appendChild(document.createElement('br'));
        }
      paragraph.scrollIntoView({block: 'start', behavior: 'smooth'});
      });

      const hostname = window.location.hostname;
      let apiUrl;
      if (hostname === 'saude.trizpar.com.br') {
        apiUrl = 'https://saude.trizpar.com.br:5002/api/converse'; // production environment
      } else if (hostname === 'test.saude.trizpar.com.br') {
        apiUrl = 'https://test.saude.trizpar.com.br:5003/api/converse'; // staging environment
      } else {
        apiUrl = 'http://192.168.1.110:5000/api/converse'; // dev environment
      }
      console.log("Hostname/APIUrl: ", apiUrl)

      // Send the form data to your server
      //      fetch('http://192.168.1.110:5000/api/converse', {
      fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json' // headers to indicate json payload
        },
        body: JSON.stringify(data) // convert data to json
      })
        .then(response => response.json()) // parse response as JSON
        .then(data => {
          // stop blinking wehn response is received
          //data.text().then(data => {
          clearInterval(blinkInterval); // stop blinking 
          // Handle the response from the server
          setCursorTransparent();
          console.log('Response from server:', data);

          // extract response message and session id from json
          const { message, session_id } = data;

          // update sessionId
          this.sessionId = session_id;

          // Process the response data
          const messageContent = message.split('\n');
          this.messages.push(...messageContent);
          messageContent.forEach((line, index) => {
            const paragraph = document.createElement('p');
            if (index == 0) {
              this.targetParagraph = paragraph;
            }
            //            paragraph.className = 'text-justify px-6 py-2';
            paragraph.className = 'px-6 py-2 bg-light-blue-lighten-5 text-grey-darken-2';
            paragraph.innerHTML = line;
            // paragraph.style.backgroundColor = '#e3ffea';
            protocolView.appendChild(paragraph);

            // Add an empty line after each post, except for the last one
            if (index == messageContent.length - 1) {
              protocolView.appendChild(document.createElement('br'));
            }
          this.targetParagraph.scrollIntoView({block: 'start', behavior: 'smooth'});
          });
          // scroll to the bottom of the protocol view
          // const lastParagraph = protocolView.querySelector('p:last-child');
          const paragraphs = protocolView.querySelectorAll('p');

          // Scroll the last paragraph into view
          console.log(protocolView)
        })
        .catch(error => {
          // Handle any errors that occur during the request
          console.error('Error:', error);
        });

      // Function to toggle cursor visibility
      function toggleCursor() {
        console.log("CURSOR BLINK")
        const cursor = document.getElementById('cursor');
        cursor.style.backgroundColor = cursor.style.backgroundColor === 'red' ? 'transparent' : 'red';
      }
      function setCursorTransparent() {
        console.log("CURSOR TRANSPARENT")
        const cursor = document.getElementById('cursor');
        cursor.style.backgroundColor = 'transparent';
      }
    }
  }
}
</script>
